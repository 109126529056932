@font-face {
  font-family: 'Commuters Sans';
  src: url('../../fonts/CommutersSans-Regular.woff2') format('woff2'),
      url('../../fonts/CommutersSans-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Commuters Sans';
  src: url('../../fonts/CommutersSans-SemiBold.woff2') format('woff2'),
      url('../../fonts/CommutersSans-SemiBold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Commuters Sans';
  src: url('../../fonts/CommutersSans-SemiBoldItalic.woff2') format('woff2'),
      url('../../fonts/CommutersSans-SemiBoldItalic.woff') format('woff');
  font-weight: 600;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Commuters Sans';
  src: url('../../fonts/CommutersSans-Italic.woff2') format('woff2'),
      url('../../fonts/CommutersSans-Italic.woff') format('woff');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Sometimes Times';
  src: url('../../fonts/SometimesTimes-Regular.woff2') format('woff2'),
      url('../../fonts/SometimesTimes-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

