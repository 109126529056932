.step-holder {
  border: 1px solid rgba(255, 255, 255, 0.16);
  display: none;
  max-width: 100%;

  &.is-visible {
    display: block;
  }
}

.step-holder__head {
  padding: 12px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.16);

  h4 {
    margin: 0;
  }

  h5 {
    margin-bottom: 5px;
    color: rgba(255, 255, 255, 0.7);
  }
}

.step-holder__body {
  position: relative;
  padding: 100px;
  min-height: 300px;

  @media (max-width: 1024px) {
    padding: 60px 30px;
  }

  .prev-step {
    position: absolute;
    left: 16px;
    bottom: 16px;

    @media (max-width: 1024px) {
      bottom: 5px;
      left: 5px;
    }
  }

  .next-step {
    position: absolute;
    bottom: 16px;
    right: 16px;

    @media (max-width: 1024px) {
      bottom: 5px;
      right: 5px;
    }
  }
}