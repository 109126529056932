.btn {
  padding: 12px 16px;
  text-transform: uppercase;
  font-family: $ff-commuters;
  cursor: pointer;
  font-weight: 600;
  background: $c-yellow-ea;
  border: 0;
  color: #000;
  appearance: none;
  border-radius: 0;
  transition: all 0.3s;

  &:hover {
    opacity: .7;
  }

  &:disabled {
    background: none;
    color: $c-grey-87;
    letter-spacing: 1px;
    cursor: initial;
  }
}