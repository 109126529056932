.sign-up {
  p {
    letter-spacing: 1px;
  }

  .left-col {
    flex: 0 0 56.6981%;

    @media (max-width: 767px) {
      margin-bottom: 20px;
    }

    img {
      max-width: 100%;
    }
  }

  .right-col {
    flex: 0 0 34.9173%;
    padding-bottom: 20px;
  }
}