.text-image {
  padding: 120px 0;
  max-width: 100%;
  overflow: hidden;

  @media (max-width: 767px) {
    padding: 40px 0 20px;
  }

  img {
    display: block;
    height: auto;
    max-width: 100%;
  }

  h2 {
    font-size: 70px;
    line-height: 1.2;
    font-family: $ff-sometimes;
    font-weight: normal;

    @media (max-width: 1024px) {
      font-size: 42px;
    }

    @media (max-width: 767px) {
      margin-bottom: 10px;
      font-size: 32px;
    }

    br {
      @media (max-width: 767px) {
        display: none;
      }
    }
  }

  h4 {
    margin-bottom: 11px;
    font-size: 18px;
    font-weight: 600;
  }

  p {
    margin-bottom: 20px;

    &.large {
      font-size: 21px;
      line-height: 1.190476;
      font-family: $ff-sometimes;

      @media (max-width: 767px) {
        font-size: 14px;
      }
    }
  }

  .flex__col--half {
    @media (max-width: 1024px) {
      flex: 0 0 48%;
    }

    @media (max-width: 767px) {
      margin-bottom: 40px;
    }

    .flex__col {
      @media (max-width: 1024px) {
        padding: 4px;
      }
    }
  }
}

.text-image__content {
  max-width: 400px;

  @media (max-width: 767px) {
    max-width: 100%;
    text-align: center;
  }
}