.sign-row {
  padding: 68px 0;
  background-color: #000;
  color: #fff;

  @media (max-width: 1024px) {
    padding: 40px 0;
  }

  @media (max-width: 767px) {
    padding: 20px 0;
  }

  h3 {
    margin-bottom: 20px;
    font-size: 29px;
    line-height: 1.172414;
    font-weight: normal;
    font-family: $ff-sometimes;

    @media (max-width: 1024px) {
      font-size: 20px;
      margin: 0;
    }

    @media (max-width: 767px) {
      text-align: center;
    }
  }

  form {
    position: relative;
    width: 330px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.5);

    @media (max-width: 767px) {
      width: 100%;
    }

    input {
      width: 100%;
      background: none;
      padding: 10px 80px 10px 0;
      border: 0;
      font-size: 18px;
      color: rgba(255, 255, 255, 0.6);
      outline: none !important;
    }

    button {
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
      background: none;
      border: 0;
      color: #fff;
      font-size: 18px;
      font-weight: 600;
      font-family: $ff-commuters;
      letter-spacing: 0.1em;
      cursor: pointer;
      appearance: none;
      outline: none;
      border-radius: 0;
    }
  }
}