.height {

}

.height__unit {
  max-width: 155px;
  border: 1px solid rgba(255, 255, 255, 0.16);
  margin-right: 70px;
  padding: 0 12px 0 4px;

  @media (max-width: 1024px) {
    margin-right: 20px;
  }

  &.is-focused {
    border-color: $c-yellow-ea;
  }

  &.has-error {
    border-color: $c-red-ef;
  }

  label {
    color: rgba(255, 255, 255, 0.7);
  }

  input {
    width: 100%;
    background: none;
    padding: 14px 5px;
    border: 0;
    outline: none;
    font-size: 15px;
    font-family: $ff-commuters;
    color: #fff;
    text-align: center;
  }
}