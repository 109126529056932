.header {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  padding: 42px 0;

  @media (max-width: 767px) {
    position: static;
    padding: 10px 0;
  }

  &.is-alt {
    background-color: #000;
  }
}

.header__logo {
  display: block;
  max-width: 180px;
  margin-left: 50px;

  @media (max-width: 1024px) {
    margin-left: 0;
  }

  @media (max-width: 767px) {
    margin: 0 auto;
  }

  svg {
    display: block;
    width: 100%;
  }

  img {
    max-width: 100%;
    height: auto;
  }
}

.header__nav {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  @media (max-width: 1024px) {
    right: 0;
    left: auto;
    transform: translate(0, -50%);
  }

  @media (max-width: 767px) {
    position: static;
    top: 100%;
    left: 0;
    transform: translate(0);
    text-align: center;
  }

  li {
    position: relative;
    display: inline-block;
    margin: 0 10px;
    
    a {
      position: relative;
      display: block;
      color: #000;
      font-size: 17px;
      font-weight: 600;
      text-transform: uppercase;
      letter-spacing: 0.1em;
      text-decoration: none;

      @media (max-width: 767px) {
        font-size: 12px;
      }
  
      &:after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 50%;
        width: 0;
        height: 1px;
        background-color: transparent;
        transition: width 0.5s;
        transform: translateX(-50%);
      }

      &:hover {
        &:after {
          width: 100%;
          background-color: #000;
        }
      }
    }
  }
}