.gradient-btn {
  display: inline-flex;
  width: 212px;
  height: 40px;
  justify-content: center;
  align-items: center;
  background: linear-gradient(90deg, #E5E900 0%, #FF724A 35.94%, #FF233C 65.62%, #1517FF 99.48%);
  color: #fff;
  font-size: 14px;
  text-decoration: none;
  transition: opacity 0.3s;
  cursor: pointer;

  @media (max-width: 767px) {
    width: 100%;
  }

  &:hover {
    opacity: .7;
  }
}
.gradient-btn-hidden {
  visibility: hidden;
  pointer-events: none;
  cursor: none;
}