.social-login {
  margin-bottom: 40px;

  @media (max-width: 767px) {
    margin-bottom: 10px;
  }

  .flex__col {
    margin-right: 36px;
    margin-bottom: 36px;

    @media (max-width: 767px) {
      margin-bottom: 10px;
    }

    &:last-child {
      margin-right: 0;
    }
  }

  a {
    display: block;
    transition: opacity 0.3s;

    &:hover {
      opacity: .7;
    }
  }
}