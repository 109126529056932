.social-links {
  display: flex;
  padding-right: 50px;

  @media (max-width: 767px) {
    padding: 0;
    margin-bottom: 20px;
    text-align: center;
    justify-content: center;
  }

  a {
    display: block;
    font-size: 0;
    line-height: 0;
    margin: 0 0 0 29px;
    transition: opacity 0.3s;

    &:hover {
      opacity: .7;
    }

    &:first-child {
      margin-left: 0;
    }
  }

  img {
    display: block;
  }
}