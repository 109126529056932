.extension-page {
  h3 {
    margin-bottom: 37px;
    padding: 0 0 14px 13px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.5);
    font-size: 13px;
    font-weight: 600;
    font-family: $ff-commuters;
    letter-spacing: 0.1em;
  }

  .ext-list {
    margin-left: 40px;
    list-style: decimal;

    li {
      padding-left: 10px;
      margin-bottom: 36px;
      opacity: .3;

      &.visible {
        opacity: 1;

        .ext-list__content {
          display: block;
        }
      }

      &.is-checked {
        .ext-list__content {
          display: none;
        }

        h5 {
          img {
            display: inline-block;
          }
        }
      }

      .btn {
        font-size: 14px;
      }
    }

    h2 {
      margin-bottom: 30px;
      font-size: 53px;
      line-height: 1.283019;
      font-family: $ff-sometimes;

      i {
        font-style: italic;
      }
    }
    
    h5 {
      margin-bottom: 26px;
      font-size: 13px;
      letter-spacing: 0.1em;
      font-family: $ff-commuters;

      img {
        display: none;
        margin-left: 5px;
      }
    }

    a {
      text-decoration: none;
    }

    .ext-list__content {
      display: none;
    }
  }

  .gradient-btn  {
    width: 122px;
    height: 45px;
    color: #fff;
  }

  .extension-row {
    @media (max-width: 1024px) {
      justify-content: space-between;
    }

    @media (max-width: 767px) {
      display: block;
    }
  }

  .extension-col {
    &--left {
      flex: 0 0 35%;
    }

    &--right {
      flex: 0 0 55%;
      padding-top: 37px;

      @media (max-width: 767px) {
        padding-top: 0;
      }

      img {
        max-width: 100%;
        height: auto;
      }
    }
  }
}