.errors {
  margin-bottom: 23px;
  text-align: center;
  border-bottom: 2px solid rgba(239, 19, 32, 0.2);;

  .error {
    margin-bottom: 8px;
    font-size: 12px;
    line-height: 1.75;
    color: $c-red-ef;
    font-family: $ff-commuters;
    letter-spacing: 1px;
  }
}