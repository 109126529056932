.tip-row {
  margin-bottom: 72px;
}

.tip-row__icon {
  display: flex;
  height: 56px;
  margin-right: 24px;
  align-self: flex-start;
  align-items: center;
  justify-content: center;
  flex: 0 0 56px;
  border: 1px solid rgba(255, 255, 255, 0.5);
}

.tip-row__content {
  flex: 1 1 auto;
  border-bottom: 1px solid rgba(255,255,255,0.2);

  h4 {
    margin: 0;
  }

  h5 {
    color: rgba(255, 255, 255, 0.7);
  }
}

.tip-row__img {
  position: relative;
  width: 80px;
  height: 80px;
  border-radius: 100%;
  background-size: cover;
  background-position: center center;
}

.tip-row__image-holder {
  margin-left: 35px;

  &:first-child {
    margin-left: 10px;
  }
}

.tip-row__img-icon {
  position: absolute;
  right: -9px;
  top: 0;
  display: flex;
  width: 27px;
  height: 27px;
  justify-content: center;
  align-items: center;
  border-radius: 100%;

  &.ok {
    background-color: $c-yellow-ea;
  }

  &.not-ok {
    background-color: $c-grey-87;
  }
}