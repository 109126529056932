.m4 {
  margin: 4px;
}

.m0-4-8 {
  margin: 0 4px 8px;
}

.m0-a {
  margin: 0 auto;
}