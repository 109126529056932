.field {
  position: relative;
  margin-bottom: 26px;

  input {
    width: 100%;
    background: none;
    border: 1px solid rgba(255,255,255,.16);
    font-size: 15px;
    color: #fff;
    padding: 18px 12px;
    border-radius: 0;
    outline: none;
  }

  label {
    position: absolute;
    top: 50%;
    left: 12px;
    color: $c-grey-87;
    transform: translateY(-50%);
    font-family: $ff-commuters;
    transition: all 0.3s;
    letter-spacing: 1px;
    font-size: 15px;
    cursor: pointer;
  }

  &.focused,
  &.not-empty {
    label {
      padding: 0 4px;
      top: 0;
      font-size: 12px;
      background-color: #000;
    }
  }

  &.not-empty {
    label {
      color: #fff;
    }
  }

  &.has-error {
    input {
      border-color: $c-red-ef;
    }

    label {
      color: $c-red-ef;
    }
  }

  &.focused {
    input {
      border-color: $c-yellow-ea;
    }
    label {
      color: $c-yellow-ea;
    }    
  }

}

.field-wrapper {
  .description {
    margin-top: -20px;
    margin-bottom: 12px;
    padding-left: 12px;
    letter-spacing: 1px;
  }

  .has-error ~ .description {
    color: $c-red-ef;
  }
}

.password-review {
  position: absolute;
  top: 50%;
  right: 16px;
  transform: translateY(-50%);
  border: 0;
  padding: 0;
  background: none;
  cursor: pointer;
}