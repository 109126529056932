.flex {
  display: flex;

  &.between {
    justify-content: space-between;
  }

  &.align-center {
    align-items: center;
  }

  &.center {
    justify-content: center;
  }

  &.mobile-block {
    @media (max-width: 767px) {
      display: block;
    }
  }
}

.flex__col--half {
  flex: 0 0 50%;
}