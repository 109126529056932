.footer {
  padding: 40px 0;
  background-color: #000;

  @media (max-width: 767px) {
    padding: 20px 0;
  }

  .footer-text {
    display: inline-block;
    font-size: 10px;
    font-style: italic;
    color: #fff;
    font-weight: 400;
    transform: translateY(5px);

    @media (max-width: 767px) {
      display: block;
      margin-bottom: 10px;
      text-align: center;
      transform: translateY(0);
    }
  }

  .footer-logo {
    display: inline-block;
    transition: opacity 0.3s;
    vertical-align: middle;
    margin-right: 20px;

    @media (max-width: 767px) {
      display: block;
      margin: 0 auto;
      text-align: center;
    }

    &:hover {
      opacity: .7;
    }
  }
}