.drag-drop {
  position: relative;
  display: flex;
  align-items: center;
  min-height: 500px;
  flex: 0 0 100%;

  .drag-drop__error {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,.8);
    text-align: center;
    padding: 27px 5px;

    p {
      margin-bottom: 5px;
    }

    .btn {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%,-50%);
      text-decoration: none;
    }
  }

  .cancel-btn {
    position: absolute;
    bottom: 16px;
    left: 16px;
    padding: 12px 16px;
    color: $c-yellow-ea;
    font-weight: 600;
    text-decoration: none;
    text-transform: uppercase;
  }
}

.dropzone {
  display: flex;
  flex: 0 0 100%;
  min-height: 500px;
  border: 2px dashed $c-yellow-ea;
  text-align: center;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
  
  &.has-preview {
    border-color: transparent;
  }
  
  p {
    font-size: 18px;
    line-height: 1.777778;
    color: #fff;

    span {
      color: $c-yellow-ea;
    }
  }
}