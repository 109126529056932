.steps {
  padding-bottom: 30px;
}

.steps-left {
  flex: 0 0 51.4122%;
  padding-bottom: 50px;

  h1 {
    em {
      background: -webkit-linear-gradient(0, #EAEA15 2.23%, #FF855F 29.99%, #FF454E  96.38%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }

  h3 {
    position: relative;
    display: inline-flex;
    padding: 0 120px 20px 0;
    font-size: 21px;
    line-height: 1;
    letter-spacing: 1px;
    font-family: $ff-sometimes;

    @media (max-width: 767px) {
      display: block;
      padding-right: 0;
    }

    &:after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 1px;
      background: linear-gradient(to right, #0031FF, #FF454E, #FF855F);
      letter-spacing: 0.1em;
    }
  }

  &.flex-end {
    align-self: flex-end;
  }
}

.steps-right {
  flex: 0 0 44.3946%;
}