h1 {
  margin-bottom: 43px;
  font-size: 53px;
  font-family: $ff-sometimes;
  font-weight: normal;
  line-height: 1.283019;
  letter-spacing: 4px;

  @media (max-width: 767px) {
    font-size: 32px;
  }
}

h4 {
  margin-bottom: 29px;
  font-size: 13px;
  line-height: 1.769231;
  font-weight: 600;
  font-family: $ff-commuters;
}

h5 {
  font-size: 13px;
  font-weight: 400;
  font-family: $ff-commuters;
}