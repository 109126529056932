.intro {
  display: flex;
  align-items: flex-end;

  @media (max-width: 767px) {
    flex-wrap: wrap;
  }

  h2 {
    margin-bottom: 15px;
    font-size: 53px;
    line-height: 1.188679;
    font-family: $ff-sometimes;
    font-weight: normal;
    letter-spacing: 4px;

    @media (max-width: 767px) {
      margin-bottom: 10px;
      font-size: 32px;
    }
  }

  p {
    margin-bottom: 30px;
    font-size: 12px;
    letter-spacing: 0.1em;

    @media (max-width: 767px) {
      margin-bottom: 20px;
    }
  }
}

.intro__col {
  flex: 0 0 50%;
  background-size: cover;
  background-position: center center;

  @media (max-width: 767px) {
    flex: 0 0 100%;
  }

  &.intro__col--image {
    height: 684px;

    @media (max-width: 767px) {
      order: -1;
      height: 320px;
    }
  }

  &.intro__col--text {
    padding: 0 70px 65px;

    @media (max-width: 1024px) {
      padding: 0 20px 40px;      
    }

    @media (max-width: 767px) {
      padding: 20px;
      text-align: center;
    }
  }
}