.radios {
  display: flex;

  .radio {
    position: relative;

    label {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 48px;
      height: 48px;
      border: 1px solid rgba(255,255,255,.12);
      font-size: 13px;
      color: rgba(255, 255, 255, 0.5);
      transition: all 0.3s;
    }

    input {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      opacity: 0;
      margin: 0;
      cursor: pointer;

      &:checked {
        & + label {
          background-color: rgba(234, 234, 21, 0.12);
          color: $c-yellow-ea;
        }
      }
    }
  }
}