.loading {
  margin-bottom: 30px;
  background-color: $c-grey-17;
  
  span {
    display: block;
    height: 4px;
    background: linear-gradient(90deg, #EAEA15 2.23%, #FF855F 29.99%, #FF454E 51.12%, #0031FF 96.38%);
    transition: width 0.3s;
  }
}

.loading-outer {
  width: 375px;
  margin: 0 auto;
  max-width: 100%;
  text-align: center;

  @media (max-width: 1200px) {
    width: 90%;
  }

  p {
    font-size: 18px;
    font-weight: 600;
    color: #fff;
    text-transform: uppercase;
  }
}