.sign-up-form {
  position: relative;
  margin-bottom: 29px;

  .errors {
    position: absolute;
    bottom: 100%;
    left: 0;
    width: 100%;
    text-align: center;
  }
}

.sign-up-form__cta {
  text-align: right;
}

.sign-up-form__submit {
  width: 104px;
  height: 45px;
  text-transform: uppercase;
  font-family: $ff-commuters;
  cursor: pointer;
  font-weight: 600;
  background: $c-yellow-ea;
  border: 0;
  color: #000;
  appearance: none;
  border-radius: 0;
  transition: all 0.3s;

  &:hover {
    opacity: .7;
  }

  &:disabled {
    background: none;
    color: $c-grey-87;
    letter-spacing: 1px;
    cursor: initial;
  }
}